/* eslint-disable react/prop-types */
import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
// eslint-disable-next-line no-unused-vars
import Img from 'gatsby-image';
import './hero.scss';

const Hero = ({ theme }) => {
  const {
    allFile: { edges: logos }
  } = useStaticQuery(graphql`
    {
      allFile(filter: { relativeDirectory: { regex: "/homepage-logos/" } }) {
        edges {
          node {
            childImageSharp {
              fixed(width: 135) {
                ...GatsbyImageSharpFixed_withWebp
              }
            }
          }
        }
      }
    }
  `);
  return (
    <section className={`hero hero-${theme}`}>
      <div className="container">
        <h1 className="hero--header">Grow your online revenue.</h1>
        <h5 className="hero--subheader">
          Get more leads and reviews. Spend more time on your actual business.
          <br />
          Relax, we&apos;ve got you covered.
        </h5>
        <a className="button button-primary" href="#schedule-a-call">
          Book FREE strategy call
        </a>
      </div>
      <div className="hero--logos">
        {logos.map(logo => {
          return (
            <div
              className="hero--logos--logo"
              key={logo.node.childImageSharp.fixed.src}
            >
              <Img fixed={logo.node.childImageSharp.fixed} />
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default Hero;
