import React from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel
} from 'react-accessible-accordion';
import './accordion.scss';

const FAQAccordion = () => (
  <Accordion allowMultipleExpanded allowZeroExpanded>
    <AccordionItem>
      <AccordionItemHeading>
        <AccordionItemButton>
          What types of businesses does this work for?
        </AccordionItemButton>
      </AccordionItemHeading>
      <AccordionItemPanel>
        <p>
          I have a lot of experience in tech working with everything from
          startups to billion dollar unicorns. I currently work primarily with
          service businesses that work out of a physical location or within a
          geographical area. This includes (but is not limited to):
        </p>
        <ul>
          <li>Law Firms</li>
          <li>Accountants</li>
          <li>HVAC</li>
          <li>Pest Control</li>
          <li>Landscaping &amp; Yard</li>
          <li>Moving Companies</li>
          <li>
            <span className="color-secondary">+ More</span>
          </li>
        </ul>
        <p>
          Working with local businesses has been very fulfilling as I have been
          able to work directly with business owners and help take their
          business to the next level. If you&apos;re not sure you&apos;re a good
          fit, let&apos;s talk and see what I can do for you.
        </p>
      </AccordionItemPanel>
    </AccordionItem>
    <AccordionItem>
      <AccordionItemHeading>
        <AccordionItemButton>Do you guarantee your work?</AccordionItemButton>
      </AccordionItemHeading>
      <AccordionItemPanel>
        <p>
          While SEO &amp; digital marketing are not exact arts,{' '}
          <strong>I absolutely guarantee results</strong>. Much of ranking your
          site on Google depends on the amount of competition, quantity of
          search queries, and overall quality of your business. While every
          situation is different, I guarantee a significant and measurable
          increase in ranking and website traffic or I work for free.
        </p>
        <p>
          *If you are a new business or new to the web, results will take a bit
          longer. Rest assured results will come and you will see consistent
          revenue for your business.
        </p>
      </AccordionItemPanel>
    </AccordionItem>
    <AccordionItem>
      <AccordionItemHeading>
        <AccordionItemButton>How long does it take?</AccordionItemButton>
      </AccordionItemHeading>
      <AccordionItemPanel>
        <p>
          As mentioned before, SEO is not an exact art. I do everything possible
          to get your site to rank higher on Google. That being said, Google
          doesn&apos;t let you cheat your way to a higher ranking. Any one that
          promises you &ldquo;Rank #1 in 30 days or less&rdquo; just isn&apos;t
          being honest or realistic.
        </p>
        <p>
          That being said, you can typically expect to see a small increase in
          web traffic in the first 30-60 days. However, it is very likely that
          you will receive a large number of positive Google reviews. After
          that, the growth becomes far more significant. After 6 months, most
          business see a pretty radical change in their Google ranking and
          amount of calls and inquiries from the internet. After the first year,
          many businesses are generating enough new customers online to sustain
          their businesses completely.
        </p>
      </AccordionItemPanel>
    </AccordionItem>
    <AccordionItem>
      <AccordionItemHeading>
        <AccordionItemButton>How much does it cost?</AccordionItemButton>
      </AccordionItemHeading>
      <AccordionItemPanel>
        <p>
          To make things easy, I help you customize the right growth package to
          fit your budget and needs. Whether you need a new website and SEO or
          just a bit of both, we can make it happen. Set up a free call to
          discuss your needs and pricing.
        </p>
      </AccordionItemPanel>
    </AccordionItem>
    <AccordionItem>
      <AccordionItemHeading>
        <AccordionItemButton>
          Does my business need a website?
        </AccordionItemButton>
      </AccordionItemHeading>
      <AccordionItemPanel>
        <p>
          I often run into business owners that feel they don&apos;t need a
          website, claiming that their business &ldquo;has been just fine
          without one so far&rdquo;. While this can be true in some cases, the
          need to establish your business online is becoming less of a luxury
          and more of a necessity. As of 2019, over 90% of consumers look online
          before making a purchase. If you are relying solely on referrals and
          repeat customers to support your business, you are missing out on
          massive amounts of untapped revenue.
        </p>
      </AccordionItemPanel>
    </AccordionItem>
    <AccordionItem>
      <AccordionItemHeading>
        <AccordionItemButton>
          Will you be better than other SEO companies I have worked with?
        </AccordionItemButton>
      </AccordionItemHeading>
      <AccordionItemPanel>
        <p>
          While this is not true for all SEOs, many larger SEO companies
          struggle to take a personalized approach, as they are balancing
          hundreds of other clients. Because of this, often times you end up
          paying for bare minimum, generic work that doesn&apos;t produce great
          results.
        </p>
        <p>
          I only take on a limited number of select SEO clients, for whom I am
          confident I can get the best results. This way, I can provide a
          tailored approach to each SEO campaign and ensure that you are getting
          the greatest return on your investment.
        </p>
      </AccordionItemPanel>
    </AccordionItem>
    <AccordionItem>
      <AccordionItemHeading>
        <AccordionItemButton>
          How will I know if it&apos;s working?
        </AccordionItemButton>
      </AccordionItemHeading>
      <AccordionItemPanel>
        <p>
          Measuring online growth can be hard and many other digital agencies
          will use cheap metrics to convince you that their work is paying off.
        </p>
        <p>
          I send you monthly SEO videos to help you see the increase in your
          website health, search ranking, traffic, and conversions. This way you
          can stay up on our progress without having to waste valuable time
          trying to track it on your own.
        </p>
      </AccordionItemPanel>
    </AccordionItem>
    <AccordionItem>
      <AccordionItemHeading>
        <AccordionItemButton>
          Will I have to pay you when I want to update my site?
        </AccordionItemButton>
      </AccordionItemHeading>
      <AccordionItemPanel>
        <p>
          <strong>No.</strong> This is a classic scam used by some web design
          companies. They offer a discount upfront, but then nickle and dime you
          for every site update after.
        </p>
        <p>
          I offer a powerful, yet simple content management system so you can
          easily make updates and changes to your website yourself. No need to
          wait for your web designer to get back to you and make your requested
          updates.
        </p>
        <p>
          Of course, if you would like help with keeping your content up to date
          or other support, we can discuss plans that support your needs.
        </p>
      </AccordionItemPanel>
    </AccordionItem>
  </Accordion>
);

export default FAQAccordion;
